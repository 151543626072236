import './App.css';
import Header from './Header.js';
import Mint from './Mint.js';
import FAQs from './FAQs.js';
import Footer from './Footer.js';
import Confetti from "./Confetti";
import ABI from "./ABI.json";

import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar} from "notistack";

function App() {
const contractAdress = "0x0463F41714D2698Ae2352Ab7418F19503c53363c";

// Confetti
const [toggleConfetti, settoggleConfetti] = useState(false);

// DarkMode
const [on, seton] = useState(true);   
const togleLight = async () => { 
    if (on === true) {
        localStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
        seton(false);
    } else {
        localStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
        seton(true);
    }
}

const storedTheme = localStorage.getItem("theme");
const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
}

// Props
const [network, setNetwork] = useState("-");
const [address, setAddress] = useState("-");
const [balance, setBalance] = useState("-");

const [minted, setMinted] = useState("-");
const [price, setPrice] = useState("-");
const [left, setLeft] = useState("-");
const [nextPrice, setNextPrice] = useState("-");

// ButtonsStages
const [buttonIntitial, setButtonIntitial] = useState(false); 
const [buttonMintHaventStarted, setButtonMintHaventStarted] = useState(false); 
const [buttonNoConnectedAccounts, setButtonNoConnectedAccounts] = useState(false);
const [buttonConnectedToWrongNetwork, setButtonConnectedToWrongNetwork] = useState(false); 
const [buttonMint, setButtonMint] = useState(false); 
const [buttonMinted, setButtonMinted] = useState(false); 
const [buttonMintEnded, setButtonMintEnded] = useState(false); 
    
function swithButtonTo(setButtonName){
    setAllButtonsToFalse();
    let _setButtonName = setButtonName;
    _setButtonName(true);
}

const setAllButtonsToFalse = async() => { 
        setButtonMintHaventStarted(false);
        setButtonNoConnectedAccounts(false);
        setButtonConnectedToWrongNetwork(false);
        setButtonMint(false);
        setButtonMinted(false);
        setButtonMintEnded(false);
}

// User interactions with page 
const pageReload = () => {
    window.location.reload();
}
if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('chainChanged', pageReload);
    window.ethereum.on('accountsChanged', pageReload)
}

// ====================================================== MAIN LOGIC ====================================================== //
// --- PHAZE I --- (Check if admin button started mint.) --- //
    const adminStart = true;   

    useEffect(() => {reviewAdminStart();}, []);

    const reviewAdminStart = async () =>  {  
        console.log("Reviewing the admin start ...");        
        if(adminStart === false){
            console.log("... admin start is set to false.")
            swithButtonTo(setButtonMintHaventStarted);
        } 
        if(adminStart === true){
            console.log("... admin start is set to true.")
            isConnected();  // It will chose the next button stage
        }   
    }   
// --- PHAZE II --- (Check for connected accounts.) --- //
    const isConnected = async() => { 
        console.log("Reviewing if there are any connected accounts ... {isConnected()}")
        if (window.ethereum && window.ethereum.isMetaMask)   { 
            const accounts = await window.ethereum.request({method: 'eth_accounts'});       
            if (accounts.length) {
                // Next button to will be chosen by the connectMM().
                console.log(`.. you're connected to: ${accounts[0]}`);
                connectMM();
            }
            else{
                swithButtonTo(setButtonNoConnectedAccounts);    
                console.log("... metamask has been found but there are no connected accounts.");
                // let variant = "info";
                // enqueueSnackbar("Metamask has been found but there are no connected accounts.", {variant});    
            } 
        }
        else {
            swithButtonTo(setButtonNoConnectedAccounts);
            console.log("... metamask have not been found.");
            // let variant = "info";
            // enqueueSnackbar("To continue you have to install MetaMask.", {variant});
        }
    }  
// --- PHAZE III --- (Connect metamask button.) --- //
    const connectMM = async() => { 
    console.log("Runing connectMM() ...");

    //// 1. CHECK //// Do you have metamask? ////    
    if (window.ethereum && window.ethereum.isMetaMask) {
        console.log("... metamask have been found.");
        try{
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts");
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAdress, ABI, signer);
        
            //// 2. CHECK //// Is the network poligon? ////
            console.log("Checking the connected network ...");
            const chain = await provider.getNetwork();
            if (chain.name === "maticmum"){                        
//              if (chain.name === "matic"){                                /// For mainet
                console.log("... connected network is a polygon.");
                // set chainName
                setNetwork("Polygon testnet");                      
//                setNetwork("Polygon");                                               /// For mainet
                // set account
                const addressFull = await provider.getSigner().getAddress();
                setAddress((addressFull.substr(0, 5)+"..."+addressFull.substr(38, 4)));
                // set balance
                const amount = await provider.getBalance(addressFull);
                const amountInNum = ethers.utils.formatEther(amount);
                setBalance(amountInNum.substr(0, 4) + " MATIC");           

                //// 3. CHECK //// Have mint started (on block)? //// 
                console.log("Checking if the mint has started ...")
                const mintStartedBlok = await contract.haveMintStarted(); 
                if(mintStartedBlok === true){
                    console.log("...  mint has started.")

                    // switch buttons
                    swithButtonTo(setButtonMint);

                    // set minted
                    const contractMinted = await contract.tokenId(); 
                    const contractMax = await contract.maxSupply(); 
                    setMinted(contractMinted + " of " + contractMax);

                    // set current price
                    const contractPrice = await contract.currentPriceInUSD(); 
                    const contractPriceInMatic = (await contract.getCurrentMintPriceInMATIC());               
                    setPrice(contractPrice + "$ (≈" + (contractPriceInMatic * (10**-18)).toFixed(2) +" MATIC)");

                    // set leftAtThatPrice
                    const _leftAtThatPrice = await contract.leftAtThatPrice(); 
                    const _changePriceEvery = await contract.changePriceEvery(); 
                    setLeft(_leftAtThatPrice + " of " + _changePriceEvery);                 

                    // set next price
                    console.log("Calculating the next price ...")
                    if(contractPrice.toNumber() === 50){
                        console.log("... the next price has been calculated. No next price.");
                        setNextPrice("-")
                    }else{
                        console.log("... the next price has been calculated.");
                        const contractLeftONE = contractPrice.toNumber() + 1;
                        setNextPrice(contractLeftONE +  "$")
                    }             
                } else {
                    swithButtonTo(setButtonMintHaventStarted);
                    console.log("...  mint has not started.")
                    console.log(mintStartedBlok);                
                }
                } else {           
                    console.log("... connected network is not a polygon.")
                    // set chainName
                    setNetwork(chain.name);
                    // set account
                    const addressFull = await provider.getSigner().getAddress();
                    setAddress((addressFull.substr(0, 5)+"..."+addressFull.substr(38, 4)));
                    // set balance
                    const amount = await provider.getBalance(addressFull);
                    const amountInNum = ethers.utils.formatEther(amount);
                    setBalance(amountInNum.substr(0, 6));       
                    // reset buttons
                    swithButtonTo(setButtonConnectedToWrongNetwork);                  
                }    
        } catch (error) {
            console.log(error);
            let _msg = (error.message + " Please try again.");
            let variant = "error";
            enqueueSnackbar(_msg, {variant}); 
        }
    } else {
        swithButtonTo(setButtonNoConnectedAccounts);
        console.log("... metamask have not been found.")
        let variant = "info";
        enqueueSnackbar("To continue you have to install MetaMask.", {variant});
    }
}
    
///------------------ SWITCH TO POLYGON function ------------------///
const switchToPoligon = async() => {
    console.log("Runing switchToPoligon() ...");
    // Testnet 
    if (window.ethereum && window.ethereum.isMetaMask){
        try {
            await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: "0x13881" }]
//            params: [{ chainId: "0x89" }]                      // For mainet 
            });
        } catch (error) {
            if (error.code === 4902) { // This error code indicates that the chain has not been added to MetaMask
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                        {
                        chainName: 'Mumbai',
                        chainId: "0x13881",
                        nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                        rpcUrls: ["https://rpc-mumbai.matic.today"]
//                        chainName: 'Polygon Mainnet',                                            // For mainet 
//                        chainId: "0x89",                                                         // For mainet 
//                        nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },        // For mainet 
//                        rpcUrls: ['https://polygon-rpc.com/']                                    // For mainet 
                        }
                        ]
                    });
                } catch (error) {
                    console.log(error);
                    let _msg = (error.message + " Please try again, or switch the network manually.");
                    let variant = "error";
                    enqueueSnackbar(_msg, {variant});
                }
            }
        }
        connectMM();
    } else {console.log("... metamask have not been found.");}   
    }

///------------------ MINT FUNCTION ------------------///
const mintFunction = async() => {
    console.log("Runing mintFunction() ...");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAdress, ABI, signer);

    console.log("Checkin if the account have enough founds ...")    
    const priceToMint = await contract.getCurrentMintPriceInMATIC();
    const bigNumberPriceToMint = ethers.BigNumber.from(priceToMint);
    console.log(bigNumberPriceToMint);

    const addressFull = await provider.getSigner().getAddress();
    const accountBalance = await provider.getBalance(addressFull);
    const bigNumberBalance = ethers.BigNumber.from(accountBalance);
    console.log(bigNumberBalance);

    const isGreaterThan = bigNumberBalance.gt(bigNumberPriceToMint);
    console.log(isGreaterThan);    

    if(isGreaterThan === false){ 
        console.log("... account does not have enough founds.")    
        let variant = "warning";
        enqueueSnackbar("You do not have enough Matic in your account.", {variant});
    }
    else{
        console.log("... account have enough founds.")    

        // --- Try to mint --- //
        console.log("Trying to mint ...");
        let variant = "info";
        enqueueSnackbar("Minting has started, follow the instructions in your wallet.", {variant});

        try {
            const tryToMint = await contract.coolMint({ value: priceToMint });
            await tryToMint.wait(); 
        
            console.log("... transaction went through.");
            settoggleConfetti(!toggleConfetti);
            let variant = "success";
            enqueueSnackbar("Congrats, you have successfully minted CLO NFT!", {variant});            
            swithButtonTo(setButtonMinted); 
        } catch (error) {
            console.log("... transaction did not went through.");
            console.log(error);
            let _msg = (error.code + " Please try again.");
            let variant = "error";
            enqueueSnackbar(_msg, {variant}); 
        }
    }    
}

// ====================================================================================================== //
    return (
        <div >
            <p className='baner'>The official mint hasn't started yet. The page you're seeing is under development. </p>
            {/* <button className='SnackbarProviderButton' onClick={() => reviewAdminStart()}>runAdminCheck</button> */}

            <div className="App">
                <Header togleLightIn={togleLight} />
                <Mint        
                    networkIn={network} addressIn={address} balanceIn={balance} mintedIn={minted} priceIn={price} leftIn={left}
                    b_mintStartedIn={buttonMintHaventStarted} b_conectedIn={buttonNoConnectedAccounts} b_onPoligonIn={buttonConnectedToWrongNetwork} 
                    b_mintNowIn={buttonMint} b_mintedIn={buttonMinted} b_mintEndIn={buttonMintEnded}
                    
                    nextPriceIn={nextPrice} setNextPriceIn={setNextPrice}

                    setB_mintStartedIn={setButtonMintHaventStarted} setB_conectedIn={setButtonNoConnectedAccounts} 
                    setB_onPoligonIn={setButtonConnectedToWrongNetwork} setB_mintNowIn={setButtonMint} setB_mintedIn={setButtonMinted} 

                    connectMMIn={connectMM}
                    switchToPoligonIn={switchToPoligon}

                    mintFunctionIn={mintFunction}
                    setB_mintEndIn={setButtonMintEnded}
                />
                <Confetti toggleConfetti={toggleConfetti} settoggleConfetti={settoggleConfetti}/>
                <SnackbarProvider action={(snackbarId) => (
                    <button className='SnackbarProviderButton' onClick={() => closeSnackbar(snackbarId)}>✖</button>
                )}/>
                <FAQs/>       
            </div>
            <Footer />                 
        </div>
    );
}

export default App;

