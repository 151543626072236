// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 840px) {
  body {
    .FAQs {
      margin: 30px 1% 100px;
    }
  }
}

.FAQs {
  margin: 30px 13% 100px;
  color: var(--font-colourIn);
  font-weight: var(--FAQsFV);
}

.question {
  margin-top: 20px;
  color: var(--font-colourIn);
}
`, "",{"version":3,"sources":["webpack://./src/FAQs.css"],"names":[],"mappings":"AAAA;EACE;IACE;MACE,qBAAqB;IACvB;EACF;AACF;;AAEA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;AAC7B","sourcesContent":["@media only screen and (max-width: 840px) {\n  body {\n    .FAQs {\n      margin: 30px 1% 100px;\n    }\n  }\n}\n\n.FAQs {\n  margin: 30px 13% 100px;\n  color: var(--font-colourIn);\n  font-weight: var(--FAQsFV);\n}\n\n.question {\n  margin-top: 20px;\n  color: var(--font-colourIn);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
