import "./Question.css";
import { useState } from "react";


function Question(props) {
    const [clicked, setClicked] = useState(false);

    return (
        <div>
            {clicked === false &&
                <div className="questionHeader" onClick={() => setClicked(true)}>
                    <p className='arrow'>︾</p>
                    <h2 className='question'>{props.question}</h2>
                    <p className='arrow'>︾</p>
                </div>
            }

            {clicked === true &&
                <div>
                    <div className="questionHeader" onClick={() => setClicked(false)}>
                        <p className='arrow'>︽</p>
                        <h2 className='question'>{props.question}</h2>
                        <p className='arrow'>︽</p>
                    </div>
                    <p className='answer'>{props.answer}</p>
                </div>
            }         
        </div>
    );
}

export default Question;