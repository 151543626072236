import "./Footer.css";

function Footer(props) {
    return (
        <div className="Footer">
                <img className="socialIcon1" width="30" height="30"  alt="twitterLogo" onClick={() => window.open('https://mobile.twitter.com/cool_lil_ones')} />
                <img className="socialIcon2" width="30" height="30"  alt="instagramLogo" onClick={() => window.open('https://www.instagram.com/cool_lil_ones_nft/')} />
                <img className="socialIcon3" width="30" height="30"  alt="openSeaLogo" onClick={() => window.open('https://opensea.io/')} />
                <img className="socialIcon4" width="30" height="30"  alt="poligonscanLogo" onClick={() => window.open('https://polygonscan.com')} />               
         </div>
    );
}

export default Footer;