// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questionHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 10px;
  padding-bottom: 0px;
}

.questionHeader:hover {
  cursor: pointer;
  color: gray;
}

.arrow {
  align-self: center;
}

.answer {
  margin-left: 1%;
  margin-right: 1%;
}
`, "",{"version":3,"sources":["webpack://./src/Question.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,8BAA8B;EAC9B,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".questionHeader {\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  border-bottom: 1px solid gray;\n  padding: 10px;\n  padding-bottom: 0px;\n}\n\n.questionHeader:hover {\n  cursor: pointer;\n  color: gray;\n}\n\n.arrow {\n  align-self: center;\n}\n\n.answer {\n  margin-left: 1%;\n  margin-right: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
