// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  background-color: var(--background-color);
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

/* ------------- ICONS ---------------- */
.socialIcon1 {
  margin: 5px 10px 7px;
  content: var(--iconTwitter);
}
.socialIcon1:hover {
  cursor: pointer;
}

.socialIcon2 {
  margin: 5px 10px 7px;
  content: var(--iconInstagram);
}
.socialIcon2:hover {
  cursor: pointer;
}

.socialIcon3 {
  margin: 5px 10px 7px;
  content: var(--iconOpenSea);
}
.socialIcon3:hover {
  cursor: pointer;
}

.socialIcon4 {
  margin: 5px 10px 7px;
  content: var(--iconPoligonScan);
}
.socialIcon4:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,SAAS;AACX;;AAEA,yCAAyC;AACzC;EACE,oBAAoB;EACpB,2BAA2B;AAC7B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;AAC/B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,2BAA2B;AAC7B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,+BAA+B;AACjC;AACA;EACE,eAAe;AACjB","sourcesContent":[".Footer {\n  background-color: var(--background-color);\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  bottom: 0;\n}\n\n/* ------------- ICONS ---------------- */\n.socialIcon1 {\n  margin: 5px 10px 7px;\n  content: var(--iconTwitter);\n}\n.socialIcon1:hover {\n  cursor: pointer;\n}\n\n.socialIcon2 {\n  margin: 5px 10px 7px;\n  content: var(--iconInstagram);\n}\n.socialIcon2:hover {\n  cursor: pointer;\n}\n\n.socialIcon3 {\n  margin: 5px 10px 7px;\n  content: var(--iconOpenSea);\n}\n.socialIcon3:hover {\n  cursor: pointer;\n}\n\n.socialIcon4 {\n  margin: 5px 10px 7px;\n  content: var(--iconPoligonScan);\n}\n.socialIcon4:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
