// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  margin-top: 6px;
  margin-bottom: 4px;
  display: flex;
  justify-content: right;
}

.iconTheme {
  margin: 0.7%;
  content: var(--iconTheme);
}
.iconTheme:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB","sourcesContent":[".Header {\n  margin-top: 6px;\n  margin-bottom: 4px;\n  display: flex;\n  justify-content: right;\n}\n\n.iconTheme {\n  margin: 0.7%;\n  content: var(--iconTheme);\n}\n.iconTheme:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
