import { useState } from "react";
import "./Mint.css";
import logo_W from './Media/logo_W.png';
import img_test from './Media/img_test.png';
import gif from './Media/gif.gif';

function Mint(props) {
    const [gifOn, setGifOn] = useState(true);

    return (
        <div className="Mint">    
        {/* ------------------------------ GIF on/off --------------------------------- */}
            <div className="gif">
                {gifOn === true &&
                    <img className="img" src={gif} alt={"gif"} onClick={() => setGifOn(false)} />
                }
                {gifOn === false &&
                    <img className="img" src={img_test} alt={"gif"} onClick={() => setGifOn(true)} />
                }          
            </div>    

        {/* ------------------------------ display data --------------------------------- */}
            <div className='mintPanel'>
                <img className="logo" src={logo_W} alt="logo" onClick={() => window.location.reload()} />
                <div className='fromWallet'>
                    <div className='oneLine'>
                        <p className='ppl'>Conected network:</p>
                        <p className='ppr'>{props.networkIn}</p>
                    </div>
                    <div className='oneLine'>
                        <p className='ppl'>Conected address:</p>
                        <p className='ppr'>{props.addressIn}</p>
                    </div>
                    <div className='oneLine'>
                        <p className='ppl'>Your balance:</p>
                        <p className='ppr'>{props.balanceIn}</p>
                    </div>             
                </div>
                <div className='fromContract'>
                    <div className='oneLine'>
                        <p className='ppl'>Minted:</p>
                        <p className='ppr'>{props.mintedIn}</p>
                    </div>
                    <div className='oneLine'>
                        <p className='ppl'>Current price:</p>
                        <p className='ppr'>{props.priceIn}</p>
                    </div>
                    <div className='oneLine'>
                        <p className='ppl'>Left at that price:</p>
                        <p className='ppr'>{props.leftIn}</p>
                    </div>
                    <div className='oneLine'>
                        <p className='ppl'>Next price:</p>
                        <p className='ppr'>{props.nextPriceIn}</p>
                    </div>   
                </div>

        {/* ------------------------------ BUTTONS  --------------------------------- */}
                <div className="buttonHodler">                
                {props.b_mintStartedIn === true &&
                    <div className="oneLineOrder">
                        <button className='buttonZero'>Mint haven't started</button>
                        <p className="buttonDescription"> Follow us on <a href="https://mobile.twitter.com/cool_lil_ones" target="_blank" rel="noreferrer">Twitter</a> to be the first one to know.</p>
                    </div>
                }
                {props.b_conectedIn === true &&
                    <div className="oneLineOrder">
                        <button className='button' onClick={() => (props.connectMMIn()) }>Connect wallet</button>
                        <p className="buttonDescription">You have to use the <a href="https://metamask.io/" target="_blank" rel="noreferrer" >MetaMask</a> wallet to participate.</p>
                    </div>
                }
                {props.b_onPoligonIn === true &&
                    <div className="oneLineOrder">
                        <button className='button' onClick={() => (props.switchToPoligonIn())}>Switch to polygon Testnet</button>       
                        <p className="buttonDescription">Confirm the popup or switch the network manually.</p>
                    </div>
                }
                {props.b_mintNowIn === true && 
                    <div className="oneLineOrder">
                        <button className='button' onClick={() => (props.mintFunctionIn())}>Mint</button>
                        <p className="buttonDescription">Mint price will be automatically calculated to corresponding value in MATIC.</p>                        
                    </div>
                } 
                {props.b_mintedIn === true &&
                    <div className="oneLineOrder">
                        <button className='button' onClick={() => (window.open("https://opensea.io/account", "_blank"))}>See your NFT on Opensea</button>
                        <p className="buttonDescription">It might take a few minutes for Opensea to update.</p>
                    </div>
                }
                {props.b_mintEndIn === true &&
                    <div className="oneLineOrder">
                        <button className='button' onClick={() => (window.open("https://opensea.io/account"))}>Mint ended.</button>
                        <p className="buttonDescription">Join us, buy on OpenSea.</p>
                    </div>
                }
                </div>
                
            </div>
        </div>
    );
}

export default Mint;