import "./Header.css";

function Header(props) {
    return (
        <div className="Header">
            <img className="iconTheme" width="30" height="30" alt="themeIcon" onClick={props.togleLightIn} />                   
        </div>
    );
}

export default Header;